import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AwardAccordion from "../components/PreviousWinners/AwardAccordion";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Stories />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stories`);
};
const Stories = () => {
  const [storiesData, setStoriesData] = useState(null);
  const { data: pageData } = useQuery("stories", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setStoriesData(pageData.data);
    }
  }, [pageData]);

  return (
    <Layout>
      {storiesData === null && <Loader />}
      <SEO title={"Stories"} />
      <div className="padding-left-8 padding-right-8 py-5">
        {storiesData && <AwardAccordion accordion={storiesData} />}
      </div>
    </Layout>
  );
};
